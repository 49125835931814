.react-datepicker__input-container .ant-input {
  color: #333333;
  height: 37px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 7px;
  width: 100%;
  line-height: 1;
  font-size: 14px;
}
.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100% !important;
}
.datepicker-error {
  border: 1px solid #ea5455;
  border-radius: 6px;
}
.z-index10 {
  z-index: 10;
}
.react-datepicker-popper {
  z-index: 205 !important;
  button {
    border: none;
  }
}
.postion-calendar {
  position: absolute;
  right: 6px;
  top: 10px;
  height: 16px;
  width: 16px;
}
.header-calendar-row {
  margin: 0;
  display: flex;
  justify-content: center;
  margin: 10px;
}
.react-datepicker__day-name {
  color: #fafafa !important;
}
.header-calendar-wrap-item {
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 5px;
  padding-left: 3px;
  padding-right: 3px;
}
.react-datepicker__header {
  background-color: #2b4990 !important;
}
.react-datepicker__month-container {
  font-size: 13px !important;
}
.react-datepicker__day--selected {
  background-color: #2b4990 !important;
}
.react-datepicker__input-container .react-datepicker__close-icon {
  padding: 0 8px 2px 0 !important;
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after {
  border-bottom-color: #2b4990 !important;
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before {
  border-bottom-color: #2b4990 !important;
}
.ant-input[disabled] {
  color: #666 !important;
}

.header-calendar-wrap-item {
  cursor: pointer;
  outline: 0;
}

button.header-calendar-wrap-item {
  text-align: center;
  svg {
    vertical-align: middle;
    margin-top: -2px;
  }
}
