@import '../../../AppVar.less';

.col-txts {
  padding-top: 7px;
  .row-txt {
    .col-txt {
      min-height: 100px;
      &:nth-child(2),
      &:nth-child(3) {
        &::before {
          content: '-';
          border-left: solid 2px #e9e9e9;
          position: absolute;
          left: -10px;
          text-indent: -999999px;
          height: 100%;
        }
      }
      .body {
        display: inline-flex;
        width: 100%;
        margin: 6px 0px;
        .icon-txt {
          float: left;
        }
        .list-txt {
          float: left;
          margin-top: 2px;
          margin-left: 5px;
          .click-link {
            text-decoration: underline;
            margin-left: 5px;
            color: @_primary-color;
            &:hover {
              color: @warning-color;
            }
          }
        }
      }
      &:nth-child(1),
      &:nth-child(2) {
        .body {
          .list-txt {
            width: calc(100% - 45px);
          }
        }
      }
    }
  }
}

/* // Medium devices tablets */
@media (min-width: 768px) and (max-width: 1024px) {
  .col-img {
  }
  .col-txts {
    overflow-x: auto;
    .row-txt {
      width: 950px;
    }
  }
}

@primary-color: #777777;@link-color: #1DA57A;@border-radius-base: 2px;