.pagination-wrapper {
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  flex: 0 auto;
  padding: 15px 0px 10px 0px;
  text-align: right;
  width: 100%;
  height: 50px;

  li.ant-pagination-total-text {
    margin: auto;
  }
}

.ant-pagination {
  font-size: 14px;
}

.ant-pagination-item {
  margin: auto 5px !important;

  &:hover {
    transition: 200ms;
    border-color: #fff;
    background-color: #fff;
  }
}

.ant-pagination-item-active {
  border-color: #2b4990 !important;

  a {
    color: #fff !important;
    background-color: #2b4990 !important;
    &:hover {
      transition: 200ms;
      color: #fff !important;
    }
  }
}

.ant-pagination.mini .ant-pagination-item:not(.ant-pagination-item-active) {
  border-color: #2b4990 !important;
}

.ant-pagination-options {
  .ant-select-selector {
    font-size: 16px;
    border: 1px solid #2b4990 !important;
  }
  .ant-select-item-option {
    font-size: 16px;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: #fff;
    background-color: #2b4990;
  }
}
