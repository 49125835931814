Input[type="color"] {
	-webkit-appearance: none;
	border: none;
	width: 20%!important;
	height: 34px!important;
}
Input[type="color"]{
	padding: 0;
}
Input[type="color"]::-webkit-color-swatch {
	border: none;
}
