.ant-table-wrapper > .ant-spin-nested-loading,
.ant-table-wrapper > .ant-spin-nested-loading > .ant-spin-container,
.ant-table-wrapper > .ant-spin-nested-loading > .ant-spin-container > .ant-table,
.ant-table-wrapper > .ant-spin-nested-loading > .ant-spin-container > .ant-table > .ant-table-container,
.ant-table-wrapper
  > .ant-spin-nested-loading
  > .ant-spin-container
  > .ant-table
  > .ant-table-container
  > .ant-table-body {
  height: 100%;
}

.ant-table {
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  color: #333333 !important;
}
.ant-typography {
  color: #333333 !important;
}

.ant-table-body {
  overscroll-behavior: contain;
}

.ant-table-thead > tr > th {
  font-weight: bold;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #333333 !important;
  padding: 14px;
  background-color: #f7f7f7 !important;
  border-top: 1px solid #e9e9e9 !important;
  border-bottom: 1px solid #e9e9e9 !important;
}

.ant-table-thead .ant-table-cell::before {
  background-color: rgba(0, 0, 0, 0) !important;
}

.ant-table-container table > thead > tr {
  height: 55px;
}

.ant-table-tbody > tr > td {
  padding: 12px 16px;
}

.ant-table-column-sorter-down.active,
.ant-table-column-sorter-up.active {
  color: #2b4990 !important;
}

//ปุ่มใน table
.ant-table-cell {
  .btn-stt-icon3 {
    min-width: 100% !important;
  }
}

.no-data {
  min-height: 200px;
  display: grid;
  align-items: center;
  color: #999;
}

.vertical-align-top {
  vertical-align: top;
  text-align: left;
}
