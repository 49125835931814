/* ---- Font NotoSansThai ---- */
@font-face {
  font-family: 'NotoSansThai';
  src: url('../fonts/NotoSansThai/NotoSansThai-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'NotoSansThai';
  src: url('../fonts/NotoSansThai/NotoSansThai-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'NotoSansThai';
  src: url('../fonts/NotoSansThai/NotoSansThai-SemiBold.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'NotoSansThai';
  src: url('../fonts/NotoSansThai/NotoSansThai-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'NotoSansThai';
  src: url('../fonts/NotoSansThai/NotoSansThai-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/*--- NotoSan For Eng ---*/

@font-face {
  font-family: 'NotoSansThai';
  src: url('../fonts/NotoSansThai/NotoSans-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'NotoSansThai';
  src: url('../fonts/NotoSansThai/NotoSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'NotoSansThai';
  src: url('../fonts/NotoSansThai/NotoSans-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'NotoSansThai';
  src: url('../fonts/NotoSansThai/NotoSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
/* ---- End Font NotoSansThai ---- */

/* ---- Font ThSarabunNew ---- */
@font-face {
  font-family: 'ThSarabunNew';
  src: url('../fonts/ThSarabunNew/Sarabun-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ThSarabunNew';
  src: url('../fonts/ThSarabunNew/Sarabun-SemiBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
/* ---- End Font ThSarabunNew ---- */

@primary-color: #777777;@link-color: #1DA57A;@border-radius-base: 2px;