@import '~antd/dist/antd.less';
@import './AppVar.less';
@import './assets/style/fonts.css';

body {
  font-family: 'NotoSansThai', sans-serif;
  overflow-y: auto;
}
.site-layout .site-layout-background {
  background: #fff;
  padding: 24px !important;
}

.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-nowrap {
  white-space: nowrap;
}

.ant-form-item {
  //ระยะห่าง input bottom
  margin-bottom: 15px;
}

/* --- Set Fonts ThSarabunNew to Ele --- */
.ant-input,
.ant-input-number-input,
.ant-select,
.ant-select-dropdown,
.ant-select-item,
.ant-form-item-explain-error {
  font-family: @font-thsarabunnew-name;
  font-size: @font-thsarabunnew-size;
}

.ant-table-container {
  font-size: @font-thsarabunnew-size;
  .ant-table-thead {
    .text-nowrap {
      white-space: nowrap;
    }
  }
  .ant-table-tbody {
    .ant-table-cell {
      font-family: @font-thsarabunnew-name;
      .ant-form-item {
        //input ใน tbl cell
        margin-bottom: 0px;
      }
    }
  }
  .ant-table-tbody > tr > td {
    //ระยะห่างแต่ละ cell
    padding: 5px 16px;
  }
  .ant-table-tbody > tr:hover > td {
    //เมื่อเมาส์ hover แต่ละแถว
    background-color: #eff4ff;
  }
  .ant-table-tbody > tr.ant-table-row-selected > td {
    background-color: #d4e1ff;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #2b4990;
    border-color: #2b4990;
  }
  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: #fff;
    animation-name: none;
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: #2b4990;
  }
}
.pagination-wrapper {
  .ant-pagination-item {
    font-family: @font-thsarabunnew-name;
    border-color: #2b4990;
    a {
      padding-top: 3px;
      line-height: 1;
      height: 100%;
      font-size: 14px;
    }
    &.ant-pagination-item-active {
      a {
        color: #fff !important;
      }
    }
  }
}

.react-datepicker-popper {
  .header-calendar-wrap-item,
  .react-datepicker__day-names {
    font-family: @font-thsarabunnew-name;
  }
}

.ant-picker-input,
.ant-picker-dropdown {
  font-family: @font-thsarabunnew-name;
  font-size: @font-thsarabunnew-size;
}
/* --- End Set Fonts ThSarabunNew to Ele --- */

/* --- Input --- */
.ant-input-group {
  display: flex;
  width: 100%;
}
.ant-input-affix-wrapper-disabled {
  height: 37px;
  align-items: center;
}
/* --- End Input --- */

/* --- Select Box --- */
.ant-select-selection-item {
  /*dropdown per page*/
  font-size: 14px;
}
.ant-select-dropdown {
  .ant-select-item {
    min-height: 22px;
    font-size: 14px;
  }
  .ant-select-item-option-content {
    line-height: 1.8;
  }
}
.ant-select-disabled {
  .ant-select-selector {
    .ant-select-selection-item {
      color: #666;
    }
  }
}
/* --- End Select Box --- */

// --- Label Star to Right ---
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}
.ant-form-item-label > label::after {
  content: '';
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block;
  margin-right: 4px;
  color: @error-color;
  font-size: 16px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
// --- End Label Start to Right ---

//--- Alert Confirm ---
.modal-confirm-custom {
  .ant-modal-confirm-body {
    .modal-confirm-icon {
      display: block;
      width: 100%;
      text-align: center;
      margin-bottom: 15px;
    }
  }
  .ant-modal-confirm-title {
    display: block;
    width: 100%;
    text-align: center;
    color: #2b4990;
    font-size: 22px;
    font-weight: 500;
    line-height: 1.7;
  }
  .ant-modal-confirm-content {
    text-align: center;
    margin-left: 0;
  }
  .ant-modal-confirm-btns {
    text-align: center;
    display: flex;
    justify-content: center;
    width: 100%;
    .ant-btn {
      font-size: 14px;
      &.ant-btn-primary {
        order: 1;
        margin-left: 0px;
        margin-right: 8px;
        border-radius: 3px;
        width: 98px;
        height: 40px;
        background-color: #2b4990;
        border: 1px solid #2b4990;
        color: #fff;
        &:hover {
          transition: 200ms;
          background-color: #2a51af;
        }
      }
      &:not(.ant-btn-primary) {
        order: 2;
        border-radius: 3px;
        width: 98px;
        height: 40px;
        background-color: #fff;
        border: 1px solid #ff9e2c;
        color: #ff9e2c;
        &:hover {
          transition: 200ms;
          background-color: #fff9f4;
        }
        &:active {
          transition: 200ms;
          background-color: #ff9e2c;
          color: #fff;
        }
      }
    }
  }
}
//--- Alert ---
.modal-alert-custom {
  .ant-modal {
    margin-top: -30px;
  }
  .ant-modal-confirm-body {
    .modal-alert-icon {
      display: block;
      width: 100%;
      text-align: center;
      margin-bottom: 15px;
    }
  }
  .ant-modal-confirm-title {
    display: block;
    width: 100%;
    text-align: center;
    color: #333;
    font-size: 22px;
    font-weight: 500;
    line-height: 1.7;
  }
  .ant-modal-confirm-content {
    text-align: center;
    margin-left: 0;
  }
  .ant-modal-confirm-btns {
    text-align: center;
    display: flex;
    justify-content: center;
    width: 100%;
    .ant-btn {
      font-size: 14px;
      &.ant-btn-primary {
        order: 1;
        margin-left: 0px;
        margin-right: 8px;
        border-radius: 3px;
        width: 98px;
        height: 40px;
        background-color: #2b4990;
        border: 1px solid #2b4990;
        color: #fff;
        &:hover {
          transition: 200ms;
          background-color: #2a51af;
        }
      }
      &:not(.ant-btn-primary) {
        order: 2;
        border-radius: 3px;
        width: 98px;
        height: 40px;
        background-color: #fff;
        border: 1px solid #ff9e2c;
        color: #ff9e2c;
        &:hover {
          transition: 200ms;
          background-color: #fff9f4;
        }
        &:active {
          transition: 200ms;
          background-color: #ff9e2c;
          color: #fff;
        }
      }
    }
  }
}
//--- End Alert Confirm ---

//--- Form ---
.ant-form-vertical {
  .ant-form-item-label {
    padding-bottom: 0px;
  }
}
//--- End Form ---

//--- Icon Noti in Menu ---
.menu-label {
  position: relative;
  .notification {
    border-radius: 50%;
    font-size: 11px;
    width: 26px;
    height: 26px;
    text-align: center;
    line-height: 1;
    padding: 0px;
    margin-top: 7px;
    position: absolute;
    right: 10px;
    font-family: 'NotoSansThai';
    color: #fff;
    border: solid 0px;
  }
}
.ant-menu-submenu-popup {
  /* // Medium devices (tablets, 768px and 991px) */
  @media (min-width: 768px) and (max-width: 991px) {
    .menu-label {
      padding-right: 40px;
      padding-left: 20px !important;
    }
  }
}
//--- End Icon Noti in Menu ---

//modal image crop
.ant-modal-wrap.img-crop-modal {
  .ant-modal-footer {
    display: flex;
    justify-content: flex-end;

    .ant-btn:nth-child(2) {
      order: 1;
      margin-left: 0px;
      margin-right: 8px;
      order: 1;
      border-radius: 3px;
      font-size: 14px;
      width: 98px;
      height: 40px;
      background-color: #2b4990;
      border: 1px solid #2b4990;
      color: #fff;
      &:hover {
        transition: 200ms;
        background-color: #2a51af;
      }
    }
    .ant-btn:nth-child(1) {
      order: 2;
      border-radius: 3px;
      font-size: 14px;
      width: 98px;
      height: 40px;
      background-color: #fff;
      border: 1px solid #ff9e2c;
      color: #ff9e2c;
      &:hover {
        transition: 200ms;
        background-color: #fff9f4;
      }
      &:active {
        transition: 200ms;
        background-color: #ff9e2c;
        color: #fff;
      }
    }
  }
}
//End modal image crop

//textarea counter
.ant-input-textarea-show-count:after {
  font-size: 11px;
  color: #b4b3b3;
  font-family: 'NotoSansThai';
}
//multiple select icon selected check
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
  color: #ffffff;
}

@primary-color: #777777;@link-color: #1DA57A;@border-radius-base: 2px;