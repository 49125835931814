.time_picker_item {
  color: #333333;
  height: 37px;
  border: 1px solid #a7a9ac;
  border-radius: 2px;
  padding: 7px;
  width: 100%;
}

.ant-picker-panel-container .ant-picker-panel {
  .ant-picker-now-btn {
    color: #666666;
    &:hover {
      transition: 200ms;
      color: #999999;
    }
  }

  li.ant-picker-ok .ant-btn {
    background-color: #2b4990 !important;
    color: #ffffff !important;
    border-radius: 2px;
    height: 30px;
  }

  .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
    background-color: #2b4990 !important;
    color: #fafafa !important;
  }

  .ant-picker-footer {
    width: auto;
  }
}

.ant-picker-input {
  input {
    line-height: 2;
    font-size: 14px !important;
  }
  .ant-picker-clear {
    color: #2b4990 !important;
  }
}

.ant-picker-input > input[disabled] {
  color: #666 !important;
}

.ant-picker.ant-picker-disabled .ant-picker-suffix {
  color: #666 !important;
}
