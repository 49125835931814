@import 'react-big-calendar/lib/sass/styles';
@import 'react-big-calendar/lib/addons/dragAndDrop/styles'; // if using DnD

.rbc-toolbar {
  position: relative;
  button {
    cursor: pointer;
  }
  .today {
    position: absolute;
    left: 0;
  }
  .btn-prev,
  .btn-next,
  .btn-today {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: none;
    background-color: #2b4990;
    &:active,
    &:focus {
      background-color: #2b4990;
    }
    &:hover {
      background-color: #1d76bb;
    }
    svg {
      display: block;
      margin-left: -10px;
      margin-top: 0px;
    }
  }
  .rbc-toolbar-label {
    flex-grow: unset;
    min-width: 170px;
    font-size: 18px;
    font-weight: 500;
  }
}
.rbc-row-content {
  .rbc-row:nth-child(2) {
    .rbc-row-segment:nth-child(2) {
      // border: solid 1px #f00;
      .rbc-event {
        background-color: #1d76bb;
        color: #fff;
      }
    }
  }

  .rbc-row:not(:nth-child(2)) {
    .rbc-row-segment {
      .rbc-event {
        background-color: #76a3c6;
        color: #000;
      }
    }
  }
  .rbc-event-content {
    font-family: 'NotoSansThai', sans-serif;
    text-align: center;
    font-size: 14px;
    span {
      display: block;
    }
  }

  .rbc-event,
  .rbc-day-slot .rbc-background-event {
    border-radius: 0px;
    outline: 0;
  }

  .rbc-date-cell > a {
    pointer-events: none;
  }
}
