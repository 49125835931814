.search-table-wrapper {
  .searchbar {
    height: 37px;
    .ant-input {
      height: unset;
    }
  }
  &.mb {
    margin-bottom: 20px;
  }
}
